import type { FormKitNode } from '@formkit/core'
import { BaseModal } from '#components'
import appointmentReasonList from '~/data/appointmentReasonList.json'
import type { AddComment } from '~/types/calendar'
import { showToast } from '~/utils/showToast'

export async function validateForm(id: string) {
  const { getNode } = await import('@formkit/core')
  const form = getNode(id)
  form?.submit()
}

export async function checkFormBeforeLeaveRoute(
  form: { node: FormKitNode } | undefined,
  originalData: any | undefined,
  modifiedData: any | undefined,
) {
  if (
    !form ||
    form.node.context?.state.submitted ||
    !form.node.context?.state.dirty
  )
    return true

  // If originalData is undefined, it means that the form is new
  if (!originalData && modifiedData) {
    return Boolean(await leaveRouteModal())
  }

  // Deep compare objects
  const areObjectsEqual = Object.keys(originalData).every((key) => {
    return (
      JSON.stringify(originalData[key]) === JSON.stringify(modifiedData[key])
    )
  })

  if (areObjectsEqual) return true

  return Boolean(await leaveRouteModal())
}

export async function getStaff() {
  const clinicStore = useClinicStore()
  const nuxtApp = useNuxtApp()

  await nuxtApp.$api
    .getStaff()
    .then((response) => {
      clinicStore.setStaff(response)
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
      window.localStorage.removeItem('clinic')
      refreshToHome()
    })
}

export function deleteStaff(id: string) {
  const nuxtApp = useNuxtApp()
  const modal = useModal()

  return new Promise((resolve) => {
    modal.open(BaseModal, {
      type: 'warning',
      title: 'Tem a certeza que quer remover este membro staff?',
      description: 'Não é possível reverter esta ação!',
      confirmText: 'Eliminar',
      confirmIcon: 'i-heroicons-trash-solid',
      preventClose: true,
      onCancel() {
        modal.close()
        resolve(false)
      },
      async onSuccess() {
        await nuxtApp.$api
          .sendConfirmationToDeleteUser(id)
          .then(() => {
            showToast(
              'success',
              'Sucesso',
              'O proprietário da clínica irá receber um email a confirmar a remoção do membro staff.',
            )
          })
          .catch((error) => {
            showToast('error', 'Erro', error)
          })

        modal.close()
        resolve(true)
      },
    })
  })
}

export async function deleteAppointment(
  isDeleted: boolean,
  appointmentId: string,
  callback?: () => void,
) {
  const nuxtApp = useNuxtApp()
  const modal = useModal()

  // If appointment is already deleted, ask if user wants to delete permanently
  if (isDeleted) {
    return new Promise((resolve) => {
      modal.open(BaseModal, {
        type: 'warning',
        title:
          'Esta marcação foi previamente desmarcada, deseja remover permanentemente esta marcação?',
        description: 'Não é possível reverter esta ação!',
        confirmText: 'Eliminar',
        confirmIcon: 'i-heroicons-trash-solid',
        preventClose: true,
        onCancel() {
          modal.close()
          resolve(false)
        },
        async onSuccess() {
          await nuxtApp.$api
            .deletePermanentlyAppointment(appointmentId)
            .then(() => {
              showToast(
                'success',
                'Sucesso',
                'Marcação desmarcada com sucesso.',
              )
            })
            .catch((error) => {
              showToast('error', 'Erro', error)
            })

          if (callback) callback()
          modal.close()
          resolve(true)
        },
      })
    })
  }

  let appointmentReason
  const result = await new Promise((resolve) => {
    modal.open(BaseModal, {
      type: 'warning',
      title: 'Cancelar esta marcação',
      description: 'Não é possível reverter esta ação!',
      confirmText: 'Cancelar',
      confirmIcon: 'i-heroicons-archive-box-x-mark-solid',
      preventClose: true,
      select: {
        placeholder: 'Motivo da Desmarcação',
        options: appointmentReasonList,
      },
      input: {
        placeholder: 'Descreva o motivo da desmarcação...',
      },
      onSelect: (e) => {
        appointmentReason = e
      },
      onInput: (e) => {
        appointmentReason = e
      },
      onCancel() {
        modal.close()
        resolve(false)
      },
      onSuccess() {
        modal.close()
        resolve(true)
      },
    })
  })

  // If cancel, then return
  if (!result) return
  if (!appointmentReason) return

  await nuxtApp.$api
    .deleteAppointment(appointmentId, appointmentReason)
    .then(() => {
      showToast('success', 'Sucesso', 'Marcação desmarcada com sucesso.')
      if (callback) callback()
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export function deleteReminder(id: string, callback?: () => void) {
  const nuxtApp = useNuxtApp()
  const modal = useModal()

  return new Promise((resolve) => {
    modal.open(BaseModal, {
      type: 'warning',
      title: 'Tem a certeza que deseja apagar este lembrete?',
      description: 'Não é possível reverter esta ação!',
      confirmText: 'Eliminar',
      confirmIcon: 'i-heroicons-trash-solid',
      preventClose: true,
      onCancel() {
        modal.close()
        resolve(false)
      },
      async onSuccess() {
        await nuxtApp.$api
          .deleteReminder(id)
          .then(async () => {
            showToast('success', 'Sucesso', 'Lembrete removido com sucesso.')
          })
          .catch((error) => {
            showToast('error', 'Erro', error)
          })

        if (callback) callback()
        modal.close()
        resolve(true)
      },
    })
  })
}

export async function addAppointmentComment(
  appointmentId: string,
  author: string,
  comment: string,
) {
  const nuxtApp = useNuxtApp()

  await nuxtApp.$api
    .addAppointmentComment(appointmentId, {
      content: comment,
      author,
      appointment: appointmentId,
    })
    .then(() => {
      showToast('success', 'Sucesso', 'Comentário adicionado com sucesso.')
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export async function updateAppointmentComment(
  appointmentId: string,
  commentId: string,
  comment: AddComment,
) {
  const nuxtApp = useNuxtApp()

  await nuxtApp.$api
    .updateAppointmentComment(appointmentId, commentId, comment)
    .then(() => {
      showToast('success', 'Sucesso', 'Comentário alterado com sucesso.')
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export async function deleteComment(appointment: string, commentId: string) {
  const nuxtApp = useNuxtApp()
  const modal = useModal()

  return new Promise((resolve) => {
    modal.open(BaseModal, {
      type: 'warning',
      title: 'Tem a certeza que deseja apagar este comentário?',
      description: 'Não é possível reverter esta ação!',
      confirmText: 'Eliminar',
      confirmIcon: 'i-heroicons-trash-solid',
      preventClose: true,
      onCancel() {
        modal.close()
        resolve(false)
      },
      async onSuccess() {
        await nuxtApp.$api
          .deleteAppointmentComment(appointment, commentId)
          .then(() => {
            showToast('success', 'Sucesso', 'Comentário removido com sucesso.')
          })
          .catch((error) => {
            showToast('error', 'Erro', error)
          })

        modal.close()
        resolve(true)
      },
    })
  })
}

export async function getPatientAppointments(patientId: string) {
  const nuxtApp = useNuxtApp()

  return await nuxtApp.$api
    .getPatientAppointments(patientId, 3)
    .then((response) => response)
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}
